import React from "react";
import "../Dashboard.css";
import powerbutton from "../Assets/power-on.png";
import { useNavigate } from "react-router-dom";
const logout = (navigate) => {
  localStorage.removeItem("authToken");
  localStorage.isAuthenticated = false;
  navigate("/login");
}
const DashboardNav = (props) => {
  const navigate = useNavigate();
  return (
    <nav className="flex w-full py-5 bg-[#181818] sans-regular">
      {/* burger starts here */}
      <div
        className="px-5 space-y-2 cursor-pointer"
        onClick={props.burgerClick}
      >
        <span className="block w-5 h-0.5 bg-gray-300"></span>
        <span className="block w-8 h-0.5 bg-gray-300"></span>
        <span className="block w-8 h-0.5 bg-gray-300"></span>
      </div>
      {/* end of burger */}
      <div onClick={() => logout(navigate)} className="w-full pr-5 text-xs text-right text-white cursor-pointer sans-regular">
        Logout
        <img src={powerbutton} className="inline w-4 mx-2" alt="" />
      </div>
    </nav>
  );
};

export default DashboardNav;
