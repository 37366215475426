import React, { Component } from "react";
import Navbar from "./Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
// this is a commentt
class MainPage extends Component {
  state = {};
  render() {
    return (
      <div className="container px-5 mx-auto sans-regular md:10 ">
        <Navbar />
        <Outlet />
        <div className="py-5 mt-10 text-xs text-center border-t-2 border-black sans-regular">
          © sareart.org, Inc. All rights reserved.
        </div>
      </div>
    );
  }
}

export default MainPage;
