import React, { Component } from "react";
import DashboardNav from "./DashboardNav/DashboardNav";
import DashboardContent from "./DashboardContent";
import "./Dashboard.css";
class Dashboard extends Component {
  state = {
    isVisible: true,
  };
  handleBurgerClick = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };
  render() {
    return (
      <div className="w-full">
        <DashboardNav burgerClick={this.handleBurgerClick} />
        <DashboardContent
          sidebarButtons={this.props.sidebarButtons}
          isVisible={this.state.isVisible}
        >{this.props.children}</DashboardContent>
      </div>
    );
  }
}

export default Dashboard;
