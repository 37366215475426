import React from "react";
import ApiName from "../../ApiName";
import { Galleria } from "primereact/galleria";
import "./Carousel.css";
const ImageConverter = (images) => {
  var result = [];
  for (let i = 0; i < images.length; i++) {
    result.push({
      itemImageSrc: ApiName + "/" + images[i].path,
      alt: "image",
    });
  }
  console.log(result);
  return result;
};
const itemTemplate = (item) => {
  return (
    <div className="relative w-full h-[60vh] lg:h-[80vh]">
      <img
        src={item.itemImageSrc}
        className="absolute inset-0 block object-cover w-full h-full "
      />
    </div>
  );
};

const Carousel = ({ images }) => {
  return (
    <div>
      {images.length !== 0 ? (
        <Galleria
          value={ImageConverter(images)}
          numVisible={5}
          circular
          showIndicators
          showIndicatorsOnItem
          autoPlay
          autoSave="true"
          showItemNavigatorsOnHover
          transitionInterval={2000}
          style={{ width: "100%" }}
          showThumbnails={false}
          showItemNavigators
          item={itemTemplate}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Carousel;
