import DashboardContainer from "../../../Dashboard_en/DashboardContainer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import ApiName from "../../../ApiName";
import $ from "jquery";
import React, { Component } from "react";
import "primeicons/primeicons.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { motion } from "framer-motion";
//git updated
class AdminPaintsList extends Component {
  state = {
    globalFilter: null,
    paints: [],
    paintsList: [],
  };
  componentDidMount() {
    this.loadAllPaints();
  }
  handlePaintRemove = (id) => {
    const accept = () => {
      var settings = {
        url: ApiName + "/Paint/" + id,
        method: "DELETE",
        timeout: 0,
        headers: {
          Authorization: localStorage.authToken,
        },
      };
      var self = this;
      $.ajax(settings).done(function () {
        self.loadAllPaints();
      });
    };
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: () => {
        accept();
      },
      reject: () => {},
    });
  };
  loadAllPaints = function () {
    var settings = {
      url: ApiName + "/Paint",
      method: "GET",
      timeout: 0,
    };
    var p = [];
    var self = this;
    $.ajax(settings).done(function (response) {
      for (let i = 0; i < response.length; i++) {
        let x = response[i].paint;
        p.push({
          id: x.id,
          paintName: x.paintName,
          paintSmallDesc: x.paintSmallDesc,
          operations: (
            <div className="inline-block">
              <Link to={"/admin/paints/editImage/" + x.id}>
                <div className="inline px-2 py-1 mx-2 text-sm text-white rounded-lg sans-regular bg-sky-500">
                  <i className="pi pi-images" style={{ color: "white" }}></i>
                </div>
              </Link>
              <Link to={"/admin/paints/editPaint/" + x.id}>
                <div className="inline px-2 py-1 mx-2 text-sm text-white bg-yellow-400 rounded-lg sans-regular">
                  <i
                    className="pi pi-file-edit"
                    style={{ color: "slateblue" }}
                  ></i>
                </div>
              </Link>
              <div
                onClick={() => self.handlePaintRemove(x.id)}
                className="inline px-2 py-1 mx-2 text-sm text-white bg-red-500 rounded-lg cursor-pointer sans-regular"
              >
                <i className="pi pi-trash" style={{ color: "white" }}></i>
              </div>
            </div>
          ),
        });
      }

      self.setState({ paints: response, paintsList: p });
    });
  };
  render() {
    return (
      <motion.div
        initial={{ opacity: 0, transition: { duration: 0.1 } }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        className="px-5"
      >
        <ConfirmDialog />
        <DashboardContainer>
          <div className="px-5">
            <Link to="/admin/paints/add">
              <div className="py-10">
                <Button label="Add a new Paint" />
              </div>
            </Link>
            <DataTable
              paginator
              rows={5}
              header="Paints List"
              rowsPerPageOptions={[5, 10, 25, 50]}
              value={this.state.paintsList}
              tableStyle={{}}
              columnResizeMode="fit"
            >
              <Column field="id" header="ID"></Column>
              <Column field="paintName" header="Name"></Column>
              <Column field="paintSmallDesc" header="Description"></Column>
              <Column field="operations" header="Operations"></Column>
            </DataTable>
          </div>
        </DashboardContainer>
      </motion.div>
    );
  }
}

export default AdminPaintsList;
