import React from "react";
import "../Dashboard.css";
import SidebarButton from "./SidebarButton";
import "../../Login/Login.css";
const DashboardSidebar = (props) => {
  return (
    <div
      className={
        props.isVisible
          ? "w-full h-full sidebar-size bg-[#0E8388]  shadow-lg box-border transition-all  scroll-smooth overflow-y-auto  md:w-1/3 md:block z-50 pt-16"
          : " w-0 h-full fixed"
      }

    >
      {props.sidebarButtons != null && props.isVisible
        ? props.sidebarButtons.map((x, i) => <SidebarButton key={i} btnObj={x} length={props.sidebarButtons.length} index={i} />)
        : ""}
    </div>
  );
};

export default DashboardSidebar;
