import React, { useEffect, useRef, useState } from "react";
import DashboardContainerHeader from "../../../Dashboard_en/Components/DashboardContainerHeader/DashboardContainerHeader";
import DashboardContainer from "../../../Dashboard_en/DashboardContainer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FileUpload } from "primereact/fileupload";
import { useNavigate, useParams } from "react-router-dom";
import $ from "jquery";
import ApiName from "../../../ApiName";
import { toast } from "react-toastify";
import "primeicons/primeicons.css";
import { motion } from "framer-motion";
import { ProgressBar } from "primereact/progressbar";
//git updated
const AdminHomeImage = () => {
  const handlePaintRemove = (id, setState) => {
    var settings = {
      url: ApiName + "/HomeImage/" + id,
      method: "DELETE",
      timeout: 0,
      headers: {
        Authorization: localStorage.authToken,
      },
    };

    $.ajax(settings).done(function (response) {
      loadImages(setState);
      toast.success("image successfuly removed !");
    });
  };
  const loadImages = (setState) => {
    var settings = {
      url: ApiName + "/HomeImage",
      method: "GET",
      timeout: 0,
    };

    $.ajax(settings)
      .done(function (response) {
        let result = [];
        for (let i = 0; i < response.length; i++) {
          result.push({
            id: response[i].id,

            image: (
              <div className="text-center">
                <img
                  src={ApiName + "/" + response[i].path}
                  alt=""
                  className="w-20"
                />
              </div>
            ),
            operations: (
              <div className="inline-block">
                <div
                  onClick={() => handlePaintRemove(response[i].id, setState)}
                  className="inline px-2 py-1 mx-2 text-sm text-white bg-red-500 rounded-lg cursor-pointer sans-regular"
                >
                  <i className="pi pi-trash" style={{ color: "white" }}></i>
                </div>
              </div>
            ),
          });
        }
        setState({ images: result });
      })
      .fail(() => {
        console.log("failed");
      });
  };
  const uploadImageHandler = (
    event,
    setState,
    ref,
    setDisability,
    setProgressBar
  ) => {
    let files = event.files;
    var form = new FormData();
    for (var i = 0; i < files.length; i++) {
      form.append("files", files[i]);
    }
    setDisability(true);
    $("#progress-wrapper").toggleClass("hidden");
    var settings = {
      url: ApiName + "/HomeImage",
      method: "POST",
      timeout: 0,
      headers: {
        Authorization: localStorage.authToken,
      },
      processData: false,
      mimeType: "multipart/form-data",
      contentType: false,
      data: form,
      xhr: () => {
        var xhr = new window.XMLHttpRequest();

        xhr.upload.addEventListener(
          "progress",
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              percentComplete = parseInt(percentComplete * 100);
              setProgressBar(percentComplete);

              if (percentComplete === 100) {
              }
            }
          },
          false
        );

        return xhr;
      },
    };

    $.ajax(settings)
      .fail(() => {
        setDisability(false);
        $("#progress-wrapper").toggleClass("hidden");
        setProgressBar(0);
      })
      .done(function (response) {
        toast.success("images added successfully");
        setProgressBar(0);
        ref.current.clear();
        $("#progress-wrapper").toggleClass("hidden");
        setDisability(false);
        loadImages(setState);
      });
  };

  const [state, setState] = useState({
    images: [],
  });

  const navigate = useNavigate();
  const [isDisabled, setDisability] = useState(false);
  const [progressBarValue, setProgressBar] = useState(0);
  useEffect(() => {
    loadImages(setState);
  }, []);
  const fileUploadref = useRef();
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 0.1 } }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="grid grid-cols-1 gap-3 px-5 "
    >
      <DashboardContainer>
        <DashboardContainerHeader
          classNames={"text-zinc-900"}
          text="Image List"
        />
        <div className="px-5 py-5">
          <DataTable
            paginator
            rows={5}
            stripedRows
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={state.images}
            tableStyle={{}}
            columnResizeMode="fit"
          >
            <Column field="id" header="ID"></Column>
            <Column field="image" header="Image"></Column>
            <Column field="operations" header="Operations"></Column>
          </DataTable>
        </div>
      </DashboardContainer>
      <DashboardContainer>
        <DashboardContainerHeader
          classNames={"text-zinc-900"}
          text="add Image"
        />
        <div id="progress-wrapper" className="hidden px-5 py-5">
          <ProgressBar value={progressBarValue}></ProgressBar>
        </div>
        <div className="p-5">
          <FileUpload
            multiple
            name="demo[]"
            accept="image/*"
            maxFileSize={1000000}
            mode="advanced"
            ref={fileUploadref}
            customUpload
            disabled={isDisabled}
            uploadHandler={(event) =>
              uploadImageHandler(
                event,
                setState,
                fileUploadref,
                setDisability,
                setProgressBar
              )
            }
            emptyTemplate={
              <p className="py-10 m-0">
                Drag and drop files to here to upload.
              </p>
            }
          />
        </div>
      </DashboardContainer>
    </motion.div>
  );
};

export default AdminHomeImage;
