import React, { useEffect } from "react";
import Dashboard from "../../Dashboard_en/Dashboard";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import ApiName from "../../ApiName";
import $ from "jquery";

const authCheck = (navigate) => {
  var settings = {
    url: ApiName + "/Auth/authCheck",
    method: "GET",
    timeout: 0,
    headers: {
      Authorization: localStorage.authToken,
      "Content-Type": "application/json",
    },
  };

  $.ajax(settings)
    .fail(() => {
      localStorage.isAuthenticated = false;
      navigate("/login");
    })
    .done(function () {
      localStorage.isAuthenticated = true;
    });
};

const AdminMain = () => {
  const navigate = useNavigate();
  useEffect(() => authCheck(navigate), []);
  return localStorage.isAuthenticated ? (
    <div>
      <Dashboard
        sidebarButtons={[
          {
            testSub: false,
            text: "Paint Management",
            pageLink: "/admin",
          },
          {
            testSub: false,
            text: "Home Management",
            pageLink: "/admin/settings/homeImages",
            // subButtons: [
            //   {
            //     text: "Home Management",
            //     link: "/admin/settings/homeImages",
            //   },
            // ],
          },
          //git updated
          {
            testSub: false,
            text: "About Management",
            pageLink: "/admin/settings/about",
          },
        ]}
      >
        <Outlet />
      </Dashboard>
    </div>
  ) : (
    <Navigate to={"/login"} />
  );
};

export default AdminMain;
