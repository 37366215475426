import React, { createRef } from "react";
import $ from "jquery";
import "./Login.css";
import ApiName from "../ApiName";
import { useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import sareLogo from "../Assets/img/SareLogo.png"
const handleLogin = (navigate) => {
  var credentials = {
    username: $("#usernameInput").val(),
    password: $("#passwordInput").val()
  }
  var settings = {
    "url": ApiName + "/Auth",
    "method": "POST",
    "timeout": 0,
    "headers": {
      "Content-Type": "application/json"
    },
    "data": JSON.stringify(credentials),
  };


  $.ajax(settings).fail((ex) => { }).done(function (response) {
    localStorage.authToken = response;
    navigate("/admin");
  });
}
//git updated
const LoginWrapper = () => {
  const toast = createRef();
  var navigate = useNavigate();
  return (
    <div className="flex items-center justify-center w-full login-screen-size">
      <div>

        <Toast ref={toast} />
        <form className="px-10 py-5 rounded shadow-lg bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 text-gray-50">
          <div className="">
            <div className="flex justify-center"><img src={sareLogo} className="w-16" /></div>
          </div>
          {/* start of input */}
          <div className="py-2 w-72">
            <label
              htmlFor="usernameInput"
              className="block py-3 text-sm font-medium text-left text-zinc-900 sans-regular"
            >
              Username :
            </label>
            <input
              type="text"
              id="usernameInput"
              className="bg-zinc-800  text-left border-gray-300 text-gray-200 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder=""
              required
            />
          </div>
          {/* start of input */}
          <div className="py-2 w-72">
            <label
              htmlFor="passwordInput"
              className="block py-3 text-sm font-medium text-left text-zinc-900 sans-regular"
            >
              Password :
            </label>
            <input
              type="password"
              id="passwordInput"
              className="bg-zinc-800  text-left  border-gray-300 text-gray-200 text-sm rounded-xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder=""
              required
            />
          </div>
          <div className="flex justify-center w-full">
            <button type="submit" onClick={(event) => {
              event.preventDefault();
              handleLogin(navigate)
            }} className="w-1/2 py-2 my-10 text-center text-white transition-all cursor-pointer rounded-3xl mt-14 bg-zinc-900 hover:bg-zinc-800 sans-regular">
              Enter
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginWrapper;
