import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Dashboard.css";
const handleClick = () => { };
const SidebarButton = (props) => {
  const obj = props.btnObj;
  const [isSubVisible, setSubVisibility] = useState(false);
  return (
    <div className="relative">
      <Link to={obj.testSub ? "#" : obj.pageLink}>
        <div
          onClick={
            obj.testSub ? () => setSubVisibility(!isSubVisible) : handleClick
          }
          className={
            props.index === 0
              ? "px-5 py-3 ml-5   text-center border-b border-b-[#CBE4DE] text-[#CBE4DE] transition-all bg-[#2C3333] cursor-pointer  sidebar-button-w hover:bg-[#181818] sans-regular rounded-tl-lg"
              : props.index === props.length - 1
                ? (isSubVisible ? "px-5 py-3 ml-5  text-center text-[#CBE4DE] transition-all bg-[#2C3333] cursor-pointer  sidebar-button-w hover:bg-[#181818] sans-regular" : "px-5 py-3 ml-5   text-center text-[#CBE4DE] transition-all bg-[#2C3333] cursor-pointer  sidebar-button-w hover:bg-[#181818] sans-regular rounded-bl-lg")
                : "px-5 py-3 ml-5   text-center border-b border-b-[#CBE4DE] text-[#CBE4DE] transition-all bg-[#2C3333] cursor-pointer  sidebar-button-w hover:bg-[#181818] sans-regular"
          }
        >
          {obj.text}
        </div>
      </Link>
      <div
        className={
          isSubVisible ? "block transition-all" : "transition-all hidden "
        }
      >
        {obj.testSub && obj.subButtons != null && obj.subButtons.length !== 0
          ? obj.subButtons.map((x, i) => (
            <div key={i} className="text-xs subpixel-antialiased text-center text-white sans-regular">
              <Link to={x.link}>
                <div
                  className={
                    i === obj.subButtons.length - 1
                      ? "sidebar-button-w text-black px-3 ml-5 py-2 bg-[#E9E8E8] rounded-bl-xl  shadow hover:bg-[#181818] hover:text-white sans-regular"
                      : "sidebar-button-w text-white px-3 py-2 ml-5 bg-[#E9E8E8] border-b shadow hover:bg-[#181818] hover:text-white  border-spacing-0 border-b-gray-400 sans-regular"
                  }
                >
                  {x.text}
                </div>
              </Link>
            </div>
          ))
          : ""}
      </div>
    </div>
  );
};

export default SidebarButton;
