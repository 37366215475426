//git updated
import React from "react";
import { motion } from "framer-motion";
const AdminAbout = () => {
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 0.1 } }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
    ></motion.div>
  );
};

export default AdminAbout;
