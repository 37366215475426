import React, { useEffect, useState } from "react";
import DashboardContainer from "../../../Dashboard_en/DashboardContainer";
import DashboardContainerHeader from "../../../Dashboard_en/Components/DashboardContainerHeader/DashboardContainerHeader";
import TextInput from "../../../Dashboard_en/Components/Inputs/TextInput/TextInput";
import TextAreaInput from "../../../Dashboard_en/Components/Inputs/TextAreaInput/TextAreaInput";
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom";
import ApiName from "../../../ApiName";
import $ from "jquery";
import { toast } from "react-toastify";
import { confirmDialog } from "primereact/confirmdialog";
import { ConfirmDialog } from "primereact/confirmdialog";
import { motion } from "framer-motion";
//git updated
const accept = (id, navigate) => {
  var settings = {
    url: ApiName + "/Paint/" + id,
    method: "PATCH",
    timeout: 0,
    headers: {
      Authorization: localStorage.authToken,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      paintName: $("#paintName").val(),
      paintDesc: $("#paintDesc").val(),
      paintSpecs: $("#paintSpecs").val(),
      paintSellURL: $("#paintSellURL").val(),
      paintSmallDesc: $("#paintSmallDesc").val(),
    }),
  };

  $.ajax(settings)
    .done(function (response) {
      toast.success("paint updated successfuly!");
      navigate("/admin");
    })
    .fail(() => {
      toast.warning("there was a problem in updating the paint!");
    });
};

const reject = () => {};

const updatePaint = (id, navigate) => {
  function acceptFunc() {
    accept(id, navigate);
  }
  confirmDialog({
    message: "Are you sure you want to proceed?",
    header: "Confirmation",
    icon: "pi pi-exclamation-triangle",
    accept: acceptFunc,
    reject: reject,
  });
};
const AdminEditPaint = () => {
  const [state, setState] = useState({
    paint: null,
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const loadDetails = (id, setState, navigate) => {
    var settings = {
      url: ApiName + "/Paint/" + id,
      method: "GET",
      timeout: 0,
    };

    $.ajax(settings)
      .done(function (response) {
        setState({ paint: response.paint });
      })
      .fail(() => {
        console.log("failed to load the paint details");
        navigate("/admin");
      });
  };
  useEffect(() => {
    loadDetails(id, setState, navigate);
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 0.1 } }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="px-5"
    >
      <ConfirmDialog />
      <DashboardContainer>
        <DashboardContainerHeader
          classNames={"text-zinc-900"}
          text="editPaint"
        />
        {state.paint != null ? (
          <div className="px-5 py-5">
            <TextInput
              value={
                state.paint != null && state.paint.paintName != null
                  ? state.paint.paintName
                  : null
              }
              label="Name"
              id="paintName"
              classNames=""
            />
            <TextInput
              value={
                state.paint != null && state.paint.paintSmallDesc != null
                  ? state.paint.paintSmallDesc
                  : null
              }
              label="Small Describtion"
              id="paintSmallDesc"
              classNames=""
            />
            <TextInput
              value={
                state.paint != null && state.paint.paintSellURL != null
                  ? state.paint.paintSellURL
                  : null
              }
              label="Sale URL"
              id="paintSellURL"
              classNames=""
            />
            <TextAreaInput
              value={
                state.paint != null && state.paint.paintDesc != null
                  ? state.paint.paintDesc
                  : null
              }
              label="Describtion"
              id="paintDesc"
              classNames=""
            />
            <TextAreaInput
              value={
                state.paint != null && state.paint.paintSpecs != null
                  ? state.paint.paintSpecs
                  : null
              }
              label="Specifications"
              id="paintSpecs"
              classNames=""
            />
            <div className="py-3"></div>
            <Button onClick={() => updatePaint(id, navigate)} label="submit" />
          </div>
        ) : (
          ""
        )}
      </DashboardContainer>
    </motion.div>
  );
};

export default AdminEditPaint;
