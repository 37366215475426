import React, { useEffect, useState } from "react";
import $ from "jquery";
import ApiName from "../../../ApiName";
import { Galleria } from "primereact/galleria";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
const PaintDetails = () => {
  const { id } = useParams();
  var [state, setState] = useState({
    paint: null,
    images: [
      {
        itemImageSrc: "",
        thumbnailImageSrc: "",
        alt: "Description for Image 1",
        title: "Title 1",
      },
    ],
  });
  const getDetails = (props) => {
    var settings = {
      url: ApiName + "/Paint/" + id,
      method: "GET",
      timeout: 0,
    };
    var result;
    $.ajax(settings).done(function (response) {
      let images = response.images;
      if (images.length !== 0)
        for (let i = 0; i < images.length; i++)
          images[i] = ApiName + "//" + images[i];

      let imageResult = [];
      for (let j = 0; j < images.length; j++)
        imageResult.push({
          itemImageSrc: images[j],
          thumbnailImageSrc: images[j],
          alt: response.paint !== null ? response.paint.paintName : "",
          title: response.paint !== null ? response.paint.paintName : "",
        });
      setState({ images: imageResult, paint: response.paint });
    });
  };

  const itemTemplate = (item) => {
    return (
      <div className="relative w-full h-[60vh] lg:h-[80vh]">
        <img
          src={item.itemImageSrc}
          alt={item.alt}
          className="absolute inset-0 block object-cover w-full h-full"
        />
      </div>
    );
  };

  const thumbnailTemplate = (item) => {
    return <img src={item.thumbnailImageSrc} className="w-5" alt={item.alt} />;
  };
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];
  useEffect(() => getDetails(), []);
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 0.1 } }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="w-full"
    >
      {state.paint !== null ? (
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 ">
          {state.images.length !== 0 ? (
            <div className="w-full ">
              <Galleria
                value={state.images}
                responsiveOptions={responsiveOptions}
                showThumbnails={false}
                numVisible={5}
                circular
                autoSave="true"
                showIndicators={state.images.length > 1}
                showIndicatorsOnItem
                // showThumbnailNavigators={true}
                showItemNavigators={state.images.length > 1}
                showItemNavigatorsOnHover={true}
                className="transition-all"
                item={itemTemplate}
                // thumbnail={thumbnailTemplate}
              />
            </div>
          ) : (
            ""
          )}
          <div className="">
            <p className="pt-5 text-lg playfair lg:pt-0">
              {state.paint.paintName !== null || state.paint.paintName !== ""
                ? state.paint.paintName
                : ""}
            </p>
            {state.paint.paintSmallDesc !== "" ||
            state.paint.paintSmallDesc !== null ? (
              <p className="pb-2 text-xs antialiased text-gray-400 rubik ">
                {state.paint.paintSmallDesc}
              </p>
            ) : (
              ""
            )}
            <p className="pt-2 antialiased playfair">Describtion</p>
            <p className="w-full text-xs antialiased text-justify text-gray-700 xl:w-2/3 break-word rubik">
              {state.paint.paintDesc !== "" ? state.paint.paintDesc : ""}
            </p>
            <p className="pt-2 antialiased playfair">Specifications</p>
            <p className="min-h-[100px]  xl:w-2/3 pb-5 w-full text-gray-700 text-xs rubik  text-justify">
              {state.paint.paintSpecs !== null || state.paint.paintSpecs !== ""
                ? state.paint.paintSpecs
                : ""}
            </p>
            <label htmlFor="productType" className="block mb-2 playfair">
              Product Type
            </label>
            <select
              id="productType"
              disabled
              className="bg-gray-50 border border-gray-300  text-gray-900 text-sm  block  p-2.5  mb-5"
            >
              <option value="US">NFT</option>
            </select>
            {/* <label htmlFor="quantity" className="block mb-2 playfair">
              Quantity
            </label>
            <input
              type="number"
              id="quantity"
              disabled
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  block p-2.5  mb-5"
            /> */}
            {/* <label
              htmlFor="productSize"
              className="block mb-2 font-serif text-gray-900"
            >
              Size
            </label>
            <div className="flex gap-3 pb-5">
              <button
                disabled
                className="inline px-2 py-1 text-gray-400 border border-gray-400 rubik"
              >
                Small
              </button>
              <button
                disabled
                className="inline px-2 py-1 text-gray-400 border border-gray-400 rubik"
              >
                Medium
              </button>
              <button
                disabled
                className="inline px-2 py-1 text-gray-400 border border-gray-400 rubik"
              >
                Large
              </button>
            </div> */}
            <a
              href={
                state.paint.paintSaleURL !== null ||
                state.paint.paintSaleURL !== ""
                  ? state.paint.paintSaleURL
                  : "#"
              }
            >
              <button
                disabled
                className="w-full py-3 mb-20 text-center text-white border cursor-pointer bg-zinc-900 hover:border-zinc-900 hover:bg-zinc-700 rubik"
              >
                Purchase on{" "}
                <span className="text-yellow-500 sans-bold">Rarible</span>
              </button>
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </motion.div>
  );
};

export default PaintDetails;
