import React from "react";

const DashboardContainer = (props) => {
  return (
    <div className=" bg-white shadow main-container rounded">
      {props.children}
    </div>
  );
};

export default DashboardContainer;
