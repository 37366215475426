
import "./App.css";
import React from 'react';
import ProjectRoutes from "./Components/ProjectRoutes/ProjectRoutes";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
function App() {
  const location = useLocation();
  document.title = 'Sare';
  return (

    <div>
      <ToastContainer />
      <ProjectRoutes />
    </div>

  );
}

export default App;
