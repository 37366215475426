import React, { useEffect, useRef, useState } from "react";
import DashboardContainerHeader from "../../../Dashboard_en/Components/DashboardContainerHeader/DashboardContainerHeader";
import DashboardContainer from "../../../Dashboard_en/DashboardContainer";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FileUpload } from "primereact/fileupload";
import { useParams } from "react-router-dom";
import $ from "jquery";
import ApiName from "../../../ApiName";
import { toast } from "react-toastify";
import "primeicons/primeicons.css";
import { motion } from "framer-motion";
import { ProgressBar } from "primereact/progressbar";
//git updated
const handlePaintRemove = (id, paintID, setState) => {
  var settings = {
    url: ApiName + "/PaintImage/" + id,
    method: "DELETE",
    timeout: 0,
    headers: {
      Authorization: localStorage.authToken,
    },
  };

  $.ajax(settings).done(function () {
    loadImages(paintID, setState);
    toast.success("image successfuly removed !");
  });
};
const setMainImage = (imageId, paintId, setState) => {
  var settings = {
    url: ApiName + "/PaintImage/setMainImage/" + imageId,
    method: "GET",
    timeout: 0,
    headers: {
      Authorization: localStorage.authToken,
    },
  };

  $.ajax(settings)
    .done(function () {
      loadImages(paintId, setState);
      toast.success("Main Image Changed Successfully!");
    })
    .fail(() => {
      toast.warning("There was a problem in setting the main Image!");
    });
};
const loadImages = (id, setState) => {
  var settings = {
    url: ApiName + "/PaintImage/" + id,
    method: "GET",
    timeout: 0,
  };

  $.ajax(settings)
    .done(function (response) {
      let result = [];
      for (let i = 0; i < response.length; i++) {
        result.push({
          id: response[i].id,
          isMain: response[i].isMain ? (
            <span className="text-green-500 sans-bold">Yes</span>
          ) : (
            <span className="text-red-500 sans-bold">No</span>
          ),
          image: (
            <div className="text-center">
              <img
                src={ApiName + "/" + response[i].paintImageLocation}
                alt=""
                className="w-20"
              />
            </div>
          ),
          operations: (
            <div className="inline-block">
              <div
                onClick={() => setMainImage(response[i].id, id, setState)}
                className="inline px-2 py-1 mx-2 text-sm text-white bg-green-500 rounded-lg cursor-pointer sans-regular"
              >
                <i className="pi pi-verified" style={{ color: "white" }}></i>
              </div>
              <div
                onClick={() => handlePaintRemove(response[i].id, id, setState)}
                className="inline px-2 py-1 mx-2 text-sm text-white bg-red-500 rounded-lg cursor-pointer sans-regular"
              >
                <i className="pi pi-trash" style={{ color: "white" }}></i>
              </div>
            </div>
          ),
        });
      }
      setState({ images: result });
    })
    .fail(() => {
      console.log("failed");
    });
};
const uploadImageHandler = (
  event,
  setState,
  paintID,
  setVisibility,
  setProgressBar,
  barRef
) => {
  let files = event.files;
  var form = new FormData();
  for (var i = 0; i < files.length; i++) {
    form.append("files", files[i]);
  }
  setVisibility(true);
  $("#progress-wrapper").toggleClass("hidden");
  var settings = {
    url: ApiName + "/Paint/addimage/" + paintID,
    method: "POST",
    timeout: 0,
    headers: {
      Authorization: localStorage.authToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    data: form,
    xhr: () => {
      var xhr = new window.XMLHttpRequest();

      xhr.upload.addEventListener(
        "progress",
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            percentComplete = parseInt(percentComplete * 100);
            setProgressBar(percentComplete);

            if (percentComplete === 100) {
            }
          }
        },
        false
      );

      return xhr;
    },
  };

  $.ajax(settings)
    .fail(() => {
      setVisibility(false);
      $("#progress-wrapper").toggleClass("hidden");
      setProgressBar(0);
      toast.warning("there was a problem in uploading the files!");
    })
    .done(function () {
      setVisibility(false);
      $("#progress-wrapper").toggleClass("hidden");
      setProgressBar(0);
      toast.success("images added successfully");
      loadImages(paintID, setState);
      barRef.current.clear();
    });
};

const AdminEditImage = () => {
  const [state, setState] = useState({
    images: [],
  });
  const [isVisible, setVisibility] = useState(false);
  const [progressBarValue, setProgressBar] = useState(0);
  const { id } = useParams();
  const barRef = useRef();
  useEffect(() => {
    loadImages(id, setState);
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 0.1 } }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="grid grid-cols-1 gap-3 px-5 "
    >
      <DashboardContainer>
        <DashboardContainerHeader
          classNames={"text-zinc-900"}
          text="Image List"
        />
        <div className="px-5 py-5">
          <DataTable
            paginator
            rows={5}
            stripedRows
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={state.images}
            tableStyle={{}}
            columnResizeMode="fit"
          >
            <Column field="id" header="ID"></Column>
            <Column field="image" header="Image"></Column>
            <Column field="isMain" header="Main Image"></Column>
            <Column field="operations" header="Operations"></Column>
          </DataTable>
        </div>
      </DashboardContainer>
      <DashboardContainer>
        <DashboardContainerHeader
          classNames={"text-zinc-900"}
          text="add Image"
        />
        <div id="progress-wrapper" className="hidden px-5 py-5">
          <ProgressBar value={progressBarValue}></ProgressBar>
        </div>
        <div className="p-5">
          <FileUpload
            multiple
            name="demo[]"
            accept="image/*"
            maxFileSize={1000000}
            mode="advanced"
            customUpload
            ref={barRef}
            disabled={isVisible}
            uploadHandler={(event) =>
              uploadImageHandler(
                event,
                setState,
                id,
                setVisibility,
                setProgressBar,
                barRef
              )
            }
            emptyTemplate={
              <p className="py-10 m-0">
                Drag and drop files to here to upload.
              </p>
            }
          />
        </div>
      </DashboardContainer>
    </motion.div>
  );
};

export default AdminEditImage;
