import React, { Component } from "react";
import ApiName from "../../../ApiName";
import $ from "jquery";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import sareLogo from "../../../Assets/img/SareLogo.png";
class ShopPage extends Component {
  state = {
    paints: [],
  };
  componentDidMount() {
    this.loadAllProducts();
  }
  loadAllProducts = () => {
    var settings = {
      url: ApiName + "/Paint",
      method: "GET",
      timeout: 0,
    };
    var self = this;
    $.ajax(settings).done(function (response) {
      self.setState({ paints: response });
    });
  };
  renderProducts = () => {
    var products = [];
    var paints = this.state.paints;
    //create a proper list of paints
    for (let i = 0; i < paints.length; i++) {
      if (paints[i].images.length !== 0) {
        let image = paints[i].images[0];
        products.push({
          id: paints[i].paint.id,
          image: image,
          name: paints[i].paint.paintName,
        });
      }
    }

    //now return the mapped array
    return products.map((x) => (
      <div className="relative w-full h-[360px]">
        <img
          src={ApiName + "/" + x.image}
          className="absolute inset-0 z-10 object-cover w-full h-full"
          alt=""
          loading="lazy"
        />
        <div className="absolute z-20 flex items-center justify-center w-full h-full transition-opacity bg-black opacity-0 hover:opacity-80">
          <div>
            <div className="text-lg text-center text-white playfair">
              {x.name}
            </div>
            <div className="block mx-auto my-3 w-28 border-b-white"></div>
            <Link key={x.id} to={"/main/paint/" + x.id}>
              <div className="block w-20 py-2 mx-auto text-xs text-center text-black bg-white rounded cursor-pointer hover:opacity-100 rubik">
                Details
              </div>
            </Link>
          </div>
        </div>
      </div>
    ));
  };
  render() {
    return (
      <motion.div
        initial={{ opacity: 0, transition: { duration: 0.1 } }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        className="min-h-[75vh]"
      >
        {this.state.paints.length !== 0 ? (
          <div className="grid gap-2 xs:grid-cols-1 md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
            {this.renderProducts()}
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-[75vh] sans-regular md:text-base">
            <div className="animate-ping">
              <img src={sareLogo} className="w-10" alt="" />
            </div>
          </div>
        )}
      </motion.div>
    );
  }
}

export default ShopPage;
