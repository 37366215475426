import React, { useState } from "react";
import DashboardContainerHeader from "../../../Dashboard_en/Components/DashboardContainerHeader/DashboardContainerHeader";
import DashboardContainer from "../../../Dashboard_en/DashboardContainer";
import TextInput from "../../../Dashboard_en/Components/Inputs/TextInput/TextInput";
import TextAreaInput from "../../../Dashboard_en/Components/Inputs/TextAreaInput/TextAreaInput";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Message } from "primereact/message";
import $ from "jquery";
import ApiName from "../../../ApiName";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ProgressBar } from "primereact/progressbar";
//git updated
const uploadImageHandler = (
  event,
  navigate,
  paintID,
  setImageAvailablity,
  setProgressBar
) => {
  let files = event.files;
  var form = new FormData();
  for (var i = 0; i < files.length; i++) {
    form.append("files", files[i]);
  }
  setImageAvailablity(false);

  $("#progress-wrapper").toggleClass("hidden");
  var settings = {
    url: ApiName + "/Paint/addimage/" + paintID,
    method: "POST",
    timeout: 0,
    headers: {
      Authorization: localStorage.authToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    data: form,
    xhr: () => {
      var xhr = new window.XMLHttpRequest();

      xhr.upload.addEventListener(
        "progress",
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            percentComplete = parseInt(percentComplete * 100);
            setProgressBar(percentComplete);

            if (percentComplete === 100) {
            }
          }
        },
        false
      );

      return xhr;
    },
  };

  $.ajax(settings)
    .fail(() => {
      setImageAvailablity(true);
      $("#progress-wrapper").toggleClass("hidden");
      toast.warning("there was a problem in uploading the Image!");
    })
    .done(function (response) {
      setImageAvailablity(true);
      $("#progress-wrapper").toggleClass("hidden");
      toast.success("images added successfully");
      navigate("/admin");
    });
};

const createPaintHandler = (
  paintID,
  setPaintID,
  imageAvailable,
  setImageAvailablity
) => {
  var settings = {
    url: ApiName + "/Paint",
    method: "POST",
    timeout: 0,
    headers: {
      Authorization: localStorage.authToken,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      paintName: $("#paintName").val(),
      paintDesc: $("#paintDesc").val(),
      paintSellURL: $("#paintSellURL").val(),
      paintSmallDesc: $("#paintSmallDesc").val(),
      paintSpecs: $("#paintSpecs").val(),
    }),
  };
  console.log(settings.data);
  $.ajax(settings)
    .done(function (response) {
      toast.success("paint created successfully!");
      setPaintID(response.id);
      setImageAvailablity(!imageAvailable);
    })
    .fail(() => {
      toast.warning("there was some kind of problem in creating process.");
    });
};
const AdminAddPaint = () => {
  const [paintID, setPaintID] = useState(null);
  const [progressBarValue, setProgressBar] = useState(0);
  const [imageAvailable, setImageAvailablity] = useState(true);
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 0.1 } }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="grid grid-cols-1 gap-3 px-5 lg:grid-cols-2 "
    >
      <div className="w-full">
        <DashboardContainer>
          <div className="w-full ">
            <div className="">
              <DashboardContainerHeader
                classNames={!imageAvailable ? "text-zinc-200" : "text-zinc-900"}
                text="Add Paint"
              />
              <div className={imageAvailable ? "hidden" : ""}>
                <Message
                  severity="success"
                  text="Paint Created Successfully. Now Add the Images"
                />
              </div>
              <div className="px-5 py-5">
                <TextInput
                  disabled={!imageAvailable}
                  label="Name"
                  id="paintName"
                  classNames=""
                />
                <TextInput
                  disabled={!imageAvailable}
                  label="Small Describtion"
                  id="paintSmallDesc"
                  classNames=""
                />
                <TextInput
                  disabled={!imageAvailable}
                  label="Sale URL"
                  id="paintSellURL"
                  classNames=""
                />
                <TextAreaInput
                  disabled={!imageAvailable}
                  label="Describtion"
                  id="paintDesc"
                  classNames=""
                />
                <TextAreaInput
                  disabled={!imageAvailable}
                  label="Specifications"
                  id="paintSpecs"
                  classNames=""
                />
                <div className="py-3"></div>
                <Button
                  onClick={() =>
                    createPaintHandler(
                      paintID,
                      setPaintID,
                      imageAvailable,
                      setImageAvailablity
                    )
                  }
                  disabled={!imageAvailable}
                  label="submit"
                />
              </div>
            </div>
            {/* upload main image */}
          </div>
        </DashboardContainer>
      </div>
      <div className="w-full">
        <DashboardContainer>
          <div className="">
            <DashboardContainerHeader
              classNames={imageAvailable ? "text-zinc-400 " : "text-zinc-900"}
              text="Add Paint Image"
            />
            {/* <div className="pt-5">
            <img src="" alt="" className="w-12" />
          </div>
          <div className="block py-5">
            <input
              disabled={imageAvailable}
              type="file"
              className="sans-regular"
            />
          </div>
          <Button disabled={imageAvailable} label="submit" className="" /> */}
            <div className="px-5 py-10 pt-10">
              <div id="progress-wrapper" className="hidden py-5">
                <ProgressBar value={progressBarValue}></ProgressBar>
              </div>
              <FileUpload
                disabled={imageAvailable}
                multiple
                name="demo[]"
                accept="image/*"
                maxFileSize={1000000}
                mode="advanced"
                customUpload
                uploadHandler={(event) =>
                  uploadImageHandler(
                    event,
                    navigate,
                    paintID,
                    setImageAvailablity,
                    setProgressBar
                  )
                }
                emptyTemplate={
                  <p className="m-0">Drag and drop files to here to upload.</p>
                }
              />
            </div>
          </div>
        </DashboardContainer>
      </div>
    </motion.div>
  );
};

export default AdminAddPaint;
