import React from "react";

const DashboardContainerHeader = ({ text, classNames }) => {
  return (
    <div
      className={
        " px-5 w-full py-3 rounded-t-xl border-b text-xl sans-bold " +
        classNames
      }
    >
      {text}
    </div>
  );
};

export default DashboardContainerHeader;
