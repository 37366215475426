import React, { Component } from 'react';
import LoginWrapper from './LoginWrapper';
import { motion } from "framer-motion"
class LoginPage extends Component {
    state = {}
    render() {
        return (<motion.div initial={{ opacity: 0, transition: { duration: 0.1 } }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }} className='min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100'>
            <LoginWrapper />
        </motion.div>);
    }
}//git updated

export default LoginPage;