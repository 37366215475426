import React from "react";
import BreadCrumb from "./DashboardBreadcrumb/BreadCrumb";
import DashboardSidebar from "./DashboardSidebar/DashboardSidebar";
import "./Dashboard.css";
const DashboardContent = (props) => {
  return (
    <div className="flex w-full h-min-screen dash-content-size bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 sans-regular">
      <DashboardSidebar
        sidebarButtons={props.sidebarButtons}
        isVisible={props.isVisible}
      />
      <div
        className={
          props.isVisible
            ? "fixed z-10 w-full transition-all h-full bg-black opacity-70 md:hidden"
            : "hidden transition-all"
        }
      ></div>
      <div
        className={
          props.isVisible
            ? " content-wrapper transition-all scroll-smooth overflow-y-auto"
            : "w-full overflow-y-auto"
        }
      >
        {/* content will go here */}
        <BreadCrumb />
        {props.children}
        {/* content ends here */}
      </div>
    </div>
  );
};

export default DashboardContent;
