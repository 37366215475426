//git updated
import { Route, Routes, useLocation } from "react-router-dom";
import React, { Component } from "react";
import LandingPage from "../LandingPage/LandingPage";
import MainPage from "../MainPage/MainPage";
import ShopPage from "../MainPage/ShopPage/ShopPage";
import AboutPage from "../MainPage/AboutPage/AboutPage";
import HomePage from "../MainPage/HomePage/HomePage";
import AdminMain from "../AdminPage/AdminMain";
import AdminPaintsList from "../AdminPage/AdminPaintsList/AdminPaintsList";
import LoginPage from "../../Login/Login";
import AdminAddPaint from "../AdminPage/AdminAddPaint/AdminAddPaint";
import PaintDetails from "../MainPage/PaintDetails/PaintDetails";
import AdminEditPaint from "../AdminPage/AdminEditPaint/AdminEditPaint";
import AdminEditImage from "../AdminPage/AdminEditImage/AdminEditImage";
import AdminHomeImage from "../AdminPage/AdminHomeImage/AdminHomeImage";
import { AnimatePresence } from "framer-motion";
import AdminAbout from "../AdminPage/AdminAbout/AdminAbout";
//git updated
const ProjectRoutes = () => {
  const location = useLocation();
  return (
    <div>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route element={<LandingPage />} path="/" exact />
          <Route element={<LoginPage />} path="login" />
          <Route element={<MainPage />} path="main">
            <Route path="home" element={<HomePage />}></Route>
            <Route path="shop" element={<ShopPage />}></Route>
            <Route path="about" element={<AboutPage />}></Route>
            <Route path="paint/:id" element={<PaintDetails />}></Route>
          </Route>
          <Route element={<AdminMain />} path="admin">
            <Route element={<AdminPaintsList />} path="" exact />
            <Route element={<AdminAddPaint />} path="paints/add" />
            <Route element={<AdminHomeImage />} path="settings/homeImages" />
            <Route element={<AdminAbout />} path="settings/about" />
            <Route element={<AdminEditPaint />} path="paints/editPaint/:id" />
            <Route element={<AdminEditImage />} path="paints/editImage/:id" />
          </Route>
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default ProjectRoutes;
