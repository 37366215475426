import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
const TextAreaInput = ({ id, styles, label, classNames, disabled, value }) => {
  return (
    <div>
      <label htmlFor="username" className="block py-2">
        {label}
      </label>
      <InputTextarea
        id={id}
        defaultValue={value != null ? value : ""}
        style={{ width: "100%" }}
        className={classNames}
        aria-describedby="username-help"
        disabled={disabled}
      />
    </div>
  );
};

export default TextAreaInput;
