import React, { Component } from "react";
import { Link } from "react-router-dom";
import SareLogo from "../../../Assets/img/SareLogo.png";
class Navbar extends Component {
  state = {
    linkShow: false,
  };
  handleLinkShow = () => {
    this.setState({ linkShow: !this.state.linkShow });
  };
  render() {
    return (
      <div className="container pt-2 pb-5 mb-10 border-b-2 border-black">
        <header className="flex mx-auto ">
          <div className="w-3/12">
            <Link to={"/"}>
              <img src={SareLogo} className="mx-2 w-14" alt="" />
            </Link>
          </div>

          <div className="flex items-end justify-end w-9/12 text-xs antialiased md:text-sm rubik h-14">
            <div
              onClick={this.handleLinkShow}
              className="px-2 space-y-2 cursor-pointer lg:hidden "
            >
              <span className="block w-8 h-0.5 bg-black"></span>
              <span className="block w-8 h-0.5 bg-black"></span>
              <span className="block w-8 h-0.5 bg-black"></span>
            </div>
            <ul className="hidden list-none lg:block">
              <li className="inline-block px-5">
                <Link to="home" className="">
                  HOME
                </Link>
              </li>
              <li className="inline-block px-1 md:px-5">
                <Link to="shop" className="">
                  SHOP
                </Link>
              </li>

              <li className="inline-block px-1 md:px-5">
                <Link to="about" className="">
                  ABOUT
                </Link>
              </li>
              <li className="inline-block px-1 md:px-5">
                <a href="https://www.instagram.com/sareabbasi7" className="">
                  INSTA
                </a>
              </li>
            </ul>
          </div>
        </header>
        <ul
          className={
            this.state.linkShow
              ? "list-none block border-t-2 opacity-100 border-t-black mt-5 transition-all pt-3"
              : "h-0 opacity-0 hidden"
          }
        >
          <li className="px-5 py-5 text-center">
            <Link to="home" className="">
              HOME
            </Link>
          </li>
          <li className="px-1 py-5 text-center md:px-5">
            <Link to="shop" className="">
              SHOP
            </Link>
          </li>

          <li className="px-1 py-5 text-center md:px-5">
            <Link to="about" className="">
              ABOUT
            </Link>
          </li>
          <li className="px-1 py-5 text-center md:px-5">
            <a href="https://www.instagram.com/sareabbasi7" className="">
              INSTA
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default Navbar;
