import React, { Component } from "react";
import "./AboutPage.css";
import aboutImage from "../../../Assets/img/AboutImage.png";
import youtubeLogo from "../../../Assets/img/youtube.png";
import emailLogo from "../../../Assets/img/email.png";
import linkedinLogo from "../../../Assets/img/linkedin.png";
import instagramLogo from "../../../Assets/img/instagram.png";
import { motion } from "framer-motion";
class AboutPage extends Component {
  state = {};
  render() {
    return (
      <motion.div
        initial={{ opacity: 0, transition: { duration: 0.1 } }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
      >
        <img src={aboutImage} className="w-full mb-10" alt="" />
        <p className="mb-10 text-sm text-justify sans-light md:text-base">
          Sare is a painting artist who has been active in this field for 20
          years. she is an expressionism artist who is very passionate about her
          work. she thinks that there is a deep connection between real-life
          events and the art on the canvas and that is what she based her art
          on. she is currently studying philosophy at Istanbul university.
        </p>
        <p className="mb-5 sans-bold">EDUCATION :</p>
        <p className="mb-5 text-xs text-justify sans-light md:text-base">
          2016-2020 : Academy of Visual Arts
        </p>
        <p className="mb-10 text-xs text-justify sans-light md:text-base">
          2022-until now : B.s of Philosophy of Istanbul University
        </p>
        <div class="video-container mb-5">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/BYwCjtyFx2Y"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <p className="w-full mx-auto mb-20 text-xs text-center md:text-sm sans-bold">
          Further - A short film on my journey of living and drawing in Turkey.
        </p>
        <p className="w-full mb-5 text-center sans-bold">Exhibitions:</p>
        <p className="text-xs text-center sans-light md:text-base">
          2019 : Handcraft international exhibition in Iran
        </p>
        <p className="text-xs text-center sans-light md:text-base">
          2019 : group exhibition in Iran _ Asman Gallery{" "}
        </p>
        <p className="text-xs text-center sans-light md:text-base">
          2019 : group exhibation in Iran _ Argavan Gallery
        </p>

        <p className="text-xs text-center sans-light md:text-base">
          2020 : individual exhibation in iran _ Azarnoor Gallery
        </p>
        <p className="text-xs text-center sans-light md:text-base">
          2021 : Istanbul IAAF online
        </p>
        <p className="text-xs text-center sans-light md:text-base">
          2022 : Istanbul ART CONTACT
        </p>
        <p className="mb-10 text-xs text-center sans-light md:text-base">
          2022 : Bodrum ART FAIR
        </p>
        <div className="flex justify-center w-full text-xs md:text-base">
          <ul className="list-none ">
            <li className="inline-block mr-2">
              <a href="https://www.youtube.com/@sareabbasi">
                <img src={youtubeLogo} className="w-6 md:w-8" alt="" />
              </a>
            </li>
            <li className="inline-block mx-2">
              <a href="mailto: sare.abbasi64@gmail.com">
                <img src={emailLogo} className="w-6 md:w-8" alt="" />
              </a>
            </li>
            <li className="inline-block mx-2">
              <a href="https://linkedin.com/in/sareh-abbasi-1a5579252">
                <img src={linkedinLogo} className="w-6 md:w-8" alt="" />
              </a>
            </li>
            <li className="inline-block ml-2">
              <a href="https://www.instagram.com/sareabbasi7">
                <img src={instagramLogo} className="w-6 md:w-8" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </motion.div>
    );
  }
}

export default AboutPage;
