import React, { useEffect } from "react";
import { useState } from "react";
import "../HomePage/HomePage.css";
import ApiName from "../../../ApiName";
import $ from "jquery";
import Carousel from "../../../Dashboard_en/DashboardBreadcrumb/Carousel";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import sareLogo from "../../../Assets/img/SareLogo.png";
const getHomeImages = (setImages) => {
  var settings = {
    url: ApiName + "/homeImage",
    method: "GET",
    timeout: 0,
    headers: {
      "Content-Type": "application/json",
    },
  };

  $.ajax(settings).done(function (response) {
    setImages(response);
  });
};
const HomePage = () => {
  const [images, setImages] = useState([]);
  useEffect(() => {
    getHomeImages(setImages);
  }, []);
  var left = false;
  return (
    <motion.div
      initial={{ opacity: 0, transition: { duration: 0.1 } }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.1 } }}
      className="container mx-auto"
    >
      {images.length !== 0 ? (
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 ">
          <div className="flex items-center justify-start ">
            <div className="w-full font-serif italic text-justify lg:w-2/3 ">
              As I work at my drawings, day after day, what seemed unattainable
              before is now gradually becoming possible. Slowly, I'm learning to
              observe and measure. I don't stand quite so helpless before nature
              any longer.
              <br />
              <br />
              <p className="rubik">-Vincent van Gogh</p>
              <br />
              <div className="hidden lg:block">
                <Link to={"/main/shop"}>
                  <div className="inline-block px-16 py-4 mt-8 not-italic text-white rounded cursor-pointer sans-regular bg-zinc-900">
                    Shop Now
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full mt">
            {images.length !== 0 ? <Carousel images={images} /> : ""}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-[75vh] sans-regular md:text-base">
          <div className="animate-ping">
            <img src={sareLogo} className="w-16" alt="" />
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default HomePage;
