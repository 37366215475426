import React from "react";
import { InputText } from "primereact/inputtext";
const TextInput = ({ id, styles, label, classNames, disabled, value }) => {
  return (
    <div>
      <label htmlFor="username" className="block py-2 sans-regular">
        {label}
      </label>
      <InputText
        defaultValue={value != null ? value : ""}
        id={id}
        style={{ width: "100%" }}
        className={classNames}
        aria-describedby="username-help"
        disabled={disabled}
      />
    </div>
  );
};

export default TextInput;
