import "./LandingPage.css";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/img/LandingPageLogo.png";
import { motion } from "framer-motion";
class LandingPage extends Component {
  state = {};
  //git updated
  render() {
    return (
      <motion.div
        initial={{ opacity: 0, transition: { duration: 0.1 } }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.1 } }}
        className="flex items-center justify-center w-full h-screen landing-page-background"
      >
        <div className="w-full py-10 bg-black bg-opacity-75">
          <div className="container flex px-24 mx-auto text-xl text-white">
            <div className="items-center hidden w-2/5 text-right md:flex">
              <ul className="w-full mx-auto list-none">
                <li className="inline-block py-10 px-14">
                  <Link to="/main/home">HOME</Link>
                </li>
                <li className="inline-block py-10 px-14">
                  <Link to="/main/shop">SHOP</Link>
                </li>
              </ul>
            </div>
            <div className="w-full text-center md:w-1/5">
              <img src={Logo} alt="" className="mx-auto w-52 " />
            </div>
            <div className="items-center hidden w-2/5 text-left md:flex">
              <ul>
                <li className="inline-block py-10 px-14">
                  <Link to="/main/about">ABOUT</Link>
                </li>
                <li className="inline-block py-10 px-14">
                  <a href="https://www.instagram.com/@sareabbasi7">INSTA</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full text-center text-white md:hidden sans-bold">
            <ul className="mt-5 list-none">
              <li className="py-2">
                <Link to="/main/home">HOME</Link>
              </li>
              <li className="py-2">
                <Link to="/main/shop">SHOP</Link>
              </li>
              <li className="py-2">
                <Link to="/main/about">ABOUT</Link>
              </li>
              <li className="py-2">
                <Link to="https://www.instagram.com/@sareabbasi7">INSTA</Link>
              </li>
            </ul>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default LandingPage;
